const DATA_TYPE_AIRCRAFTS = 'aircrafts';
const DATA_TYPE_AIRCRAFT_CATEGORIES = 'aircraft_categories';
const DATA_TYPE_ANIMATIONS = 'animations';
const DATA_TYPE_ANIMATION_CATEGORIES = 'brand_categories';
const DATA_TYPE_BRANDS = 'brands';
const DATA_TYPE_BRAND_CATEGORIES = 'brand_categories';
const DATA_TYPE_COUNTRIES = 'countries';
const DATA_TYPE_EVENTS = 'events';
const DATA_TYPE_EVENT_CATEGORIES = 'event_categories';
const DATA_TYPE_EXHIBITORS = 'exhibitors';
const DATA_TYPE_EXHIBITOR_CATEGORIES = 'exhibitor_categories';
const DATA_TYPE_FLIGHTS_SCHEDULE = 'flights_schedule';
const DATA_TYPE_ICONS = 'icons';
const DATA_TYPE_ICON_CATEGORIES = 'icon_categories';
const DATA_TYPE_ICONS_CATEGORIES = 'icons_categories';
const DATA_TYPE_INFOS = 'infos';
const DATA_TYPE_KLIPSO_LEADS_CONTACTS = 'kleads_contacts';
const DATA_TYPE_NEWPRODUCTS = 'newproducts';
const DATA_TYPE_NEWPRODUCT_CATEGORIES = 'newproduct_categories';
const DATA_TYPE_PLACES = 'places';
const DATA_TYPE_PLACE_MEMBERS = 'place_members';
const DATA_TYPE_GMAP_PLACES = 'gmapplaces';
const DATA_TYPE_PMRS = 'pmrs';
const DATA_TYPE_RESTAURANTS = 'restaurants';
const DATA_TYPE_RESTAURANT_CATEGORIES = 'restaurant_categories';
const DATA_TYPE_MONUMENTS = 'monuments';
const DATA_TYPE_SERVICES = 'services';
const DATA_TYPE_SERVICE_CATEGORIES = 'service_categories';
const DATA_TYPE_SPEAKERS = 'speakers';
const DATA_TYPE_SPEAKER_CATEGORIES = 'speaker_categories';
const DATA_TYPE_FAVORITE_POSITIONS = 'favoritepositions';
const DATA_TYPE_DOCUNITS = 'docunits';
const DATA_TYPE_DOCUNIT_CATEGORIES = 'docunit_categories';
const DATA_TYPE_DOCUMENTS = 'documents';
const DATA_TYPE_PAPER_CATEGORIES = 'paper_categories';
const DATA_TYPE_PAPERS = 'papers';
const DATA_TYPE_PARTICIPANTS = 'participants';
const DATA_TYPE_CONTACTED_PARTICIPANTS = 'contacted_participants';
const DATA_TYPE_CONTACTS = 'contacts';
const DATA_TYPE_GOLDENSUMMIT = 'goldensummits';
const DATA_TYPE_GOLDENSUMMIT_CATEGORIES = 'goldensummit_categories';
const DATA_TYPE_USERPROJECTS = 'userprojects';
const DATA_TYPE_USERPROJECT_CATEGORIES = 'userproject_categories';
const DATA_TYPE_JOBOFFERS = 'joboffers';
const DATA_TYPE_JOBOFFER_CATEGORIES = 'joboffer_categories';
const DATA_TYPE_CONTESTS = 'contests';
const DATA_TYPE_CONTEST_CATEGORIES = 'contest_categories';
const DATA_TYPE_MEETING_CONTACTS = 'meetingContacts';
const DATA_TYPE_LIVESTREAM = 'liveStream';
const DATA_TYPE_HAPPENINGS = 'happenings';
const DATA_TYPE_BUILDINGS = 'buildings';
const DATA_TYPE_FLOORS = 'floors';
const DATA_TYPE_STORES = 'stores';
const DATA_TYPE_STORE_CATEGORIES = 'store_categories';

/** **************************************************** */
// Data update permissions
const DATA_AUTO_UPDATE = true;
const AUTO_RELOAD_MAP_AT_UPDATE = true;
/** **************************************************** */

/** **************************************************** */
/** ********* TO UPDATE FOR EVERY PROJECT ************** */
/** **************************************************** */

/**
 * Used data types on this project
 */
const VALID_DATA_TYPES = [
// DATA_TYPE_AIRCRAFTS,
// DATA_TYPE_AIRCRAFT_CATEGORIES,
DATA_TYPE_ANIMATIONS,
// DATA_TYPE_BRANDS,
// DATA_TYPE_BRAND_CATEGORIES,
// DATA_TYPE_COUNTRIES,
DATA_TYPE_ANIMATION_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_CONTESTS, DATA_TYPE_CONTEST_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES,
// DATA_TYPE_FLIGHTS_SCHEDULE,
DATA_TYPE_ICONS,
// DATA_TYPE_INFOS,
DATA_TYPE_KLIPSO_LEADS_CONTACTS, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_PLACES, DATA_TYPE_GMAP_PLACES,
// DATA_TYPE_PMRS,
// DATA_TYPE_RESTAURANTS,
// DATA_TYPE_RESTAURANT_CATEGORIES,
DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SPEAKERS, DATA_TYPE_SPEAKER_CATEGORIES, DATA_TYPE_FAVORITE_POSITIONS,
// MobiGeo favorite positions
// DATA_TYPE_DOCUNITS,
// DATA_TYPE_DOCUNIT_CATEGORIES,
// DATA_TYPE_DOCUMENTS
DATA_TYPE_PAPERS, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_CONTACTS, DATA_TYPE_GOLDENSUMMIT, DATA_TYPE_GOLDENSUMMIT_CATEGORIES, DATA_TYPE_USERPROJECTS, DATA_TYPE_USERPROJECT_CATEGORIES, DATA_TYPE_JOBOFFERS, DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_MEETING_CONTACTS, DATA_TYPE_BUILDINGS, DATA_TYPE_FLOORS, DATA_TYPE_STORE_CATEGORIES, DATA_TYPE_HAPPENINGS, DATA_TYPE_LIVESTREAM, DATA_TYPE_STORES];
const FORCE_ONLINE_ASSETS = undefined;
function getSpeakerTitle(speaker) {
  let value = '';
  if (speaker) {
    if (speaker.civility) {
      if (speaker.civility !== 'Monsieur' && speaker.civility !== 'Madame') value += "".concat(speaker.civility, " ");
    }
    if (speaker.lastname) {
      value += "".concat(speaker.lastname);
    }
    if (speaker.firstname) {
      value += ", ".concat(speaker.firstname);
    }
  }
  return value;
}
function getSpeakerSubtitle(speaker) {
  const value = [];
  if (speaker) {
    if (speaker.organisation) {
      value.push(speaker.organisation);
      if (speaker.position) {
        value.push(speaker.position);
      }
    } else if (speaker.country) {
      value.push(speaker.country);
    } else if (speaker.lump && speaker.lump.type) {
      value.push(speaker.lump.type);
    }
  }
  return value.join('<br/>');
}

/**
 * Define here which exhibitor field must be used when calling Taiga web services
 * (e.g retrieving participants of an exhibitor by calling WS)
 *
 * @param  {object} exh
 * @return {string}
 */
function getExhibitorGaiaId(exh) {
  if (!exh) {
    return null;
  }
  return exh.original_id; // when exhibitor data is provided by taiga/gaia
  // return exh.lump.GaiaId; // when exhibitor data is provided by another source
}

/**
 * Define here which participant field must be used to be able
 * to find an exhibitor in our local data (db json - Query.find(exhId, DATA_TYPE_EXHIBITORS))
 *
 * @param  {object} participant
 * @return {string}
 */
function getParticipantExhibitorOriginalId(participant) {
  if (!participant) {
    return null;
  }
  return participant.exhibitor ? participant.exhibitor.exhibitorId : null; // when exhibitor data is provided by taiga/gaia

  // return participant.exhibitor.dossierGsmId; // when exhibitor data is provided by another source
}

/**
 * Define here which participant field must be used to be able
 * to find an exhibitor in using Gaia/Taiga web services
 *
 * @param  {object} participant
 * @return {string}
 */
function getParticipantExhibitorGaiaId(participant) {
  if (!participant) {
    return null;
  }
  return participant.exhibitor ? participant.exhibitor.exhibitorId : null; // when exhibitor data is provided by taiga/gaia
}

/** **************************************************** */
/** ***************** UPDATE END *********************** */
/** **************************************************** */

/**
 * NB: `CATEGORIES_DATA_TYPE` and `NON_CATEGORIES_DATA_TYPE` are used to
 * define lists displayed in app as WHOLE PAGES. @see ListPage, listPageReducer
 * @type {Array}
 */

const CATEGORIES_DATA_TYPE = [DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_ANIMATION_CATEGORIES, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_CONTEST_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SPEAKER_CATEGORIES, DATA_TYPE_DOCUNIT_CATEGORIES, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_DOCUMENTS, DATA_TYPE_GOLDENSUMMIT_CATEGORIES, DATA_TYPE_USERPROJECT_CATEGORIES, DATA_TYPE_JOBOFFER_CATEGORIES, DATA_TYPE_STORE_CATEGORIES];
const NON_CATEGORIES_DATA_TYPE = [DATA_TYPE_ANIMATIONS, DATA_TYPE_AIRCRAFTS, DATA_TYPE_BRANDS, DATA_TYPE_EVENTS, DATA_TYPE_CONTESTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_FLIGHTS_SCHEDULE, DATA_TYPE_INFOS, DATA_TYPE_KLIPSO_LEADS_CONTACTS, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_PMRS, DATA_TYPE_RESTAURANTS, DATA_TYPE_MONUMENTS, DATA_TYPE_SERVICES, DATA_TYPE_SPEAKERS, DATA_TYPE_FAVORITE_POSITIONS, DATA_TYPE_DOCUNITS, DATA_TYPE_PAPERS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_CONTACTS, DATA_TYPE_GOLDENSUMMIT, DATA_TYPE_USERPROJECTS, DATA_TYPE_JOBOFFERS, DATA_TYPE_MEETING_CONTACTS, DATA_TYPE_HAPPENINGS, DATA_TYPE_GMAP_PLACES];
const CATEGORIES_MAPPING = {
  [DATA_TYPE_AIRCRAFT_CATEGORIES]: DATA_TYPE_AIRCRAFTS,
  [DATA_TYPE_BRAND_CATEGORIES]: DATA_TYPE_BRANDS,
  [DATA_TYPE_EVENT_CATEGORIES]: DATA_TYPE_EVENTS,
  [DATA_TYPE_CONTEST_CATEGORIES]: DATA_TYPE_CONTESTS,
  [DATA_TYPE_EXHIBITOR_CATEGORIES]: DATA_TYPE_EXHIBITORS,
  [DATA_TYPE_NEWPRODUCT_CATEGORIES]: DATA_TYPE_NEWPRODUCTS,
  [DATA_TYPE_RESTAURANT_CATEGORIES]: DATA_TYPE_RESTAURANTS,
  [DATA_TYPE_SERVICE_CATEGORIES]: DATA_TYPE_SERVICES,
  [DATA_TYPE_SPEAKER_CATEGORIES]: DATA_TYPE_SPEAKERS,
  [DATA_TYPE_DOCUNIT_CATEGORIES]: DATA_TYPE_DOCUNITS,
  [DATA_TYPE_PAPER_CATEGORIES]: DATA_TYPE_PAPERS,
  [DATA_TYPE_GOLDENSUMMIT_CATEGORIES]: DATA_TYPE_GOLDENSUMMIT,
  [DATA_TYPE_USERPROJECT_CATEGORIES]: DATA_TYPE_USERPROJECTS,
  [DATA_TYPE_JOBOFFER_CATEGORIES]: DATA_TYPE_JOBOFFERS,
  [DATA_TYPE_STORE_CATEGORIES]: DATA_TYPE_STORES,
  [DATA_TYPE_ANIMATION_CATEGORIES]: DATA_TYPE_ANIMATIONS
};
module.exports = {
  DATA_TYPE_AIRCRAFTS,
  DATA_TYPE_AIRCRAFT_CATEGORIES,
  DATA_TYPE_ANIMATIONS,
  DATA_TYPE_BRANDS,
  DATA_TYPE_BRAND_CATEGORIES,
  DATA_TYPE_ANIMATION_CATEGORIES,
  DATA_TYPE_COUNTRIES,
  DATA_TYPE_EVENTS,
  DATA_TYPE_EVENT_CATEGORIES,
  DATA_TYPE_CONTESTS,
  DATA_TYPE_CONTEST_CATEGORIES,
  DATA_TYPE_EXHIBITORS,
  DATA_TYPE_EXHIBITOR_CATEGORIES,
  DATA_TYPE_FLIGHTS_SCHEDULE,
  DATA_TYPE_ICONS,
  DATA_TYPE_ICON_CATEGORIES,
  DATA_TYPE_ICONS_CATEGORIES,
  DATA_TYPE_INFOS,
  DATA_TYPE_KLIPSO_LEADS_CONTACTS,
  DATA_TYPE_NEWPRODUCTS,
  DATA_TYPE_NEWPRODUCT_CATEGORIES,
  DATA_TYPE_PLACES,
  DATA_TYPE_PLACE_MEMBERS,
  DATA_TYPE_GMAP_PLACES,
  DATA_TYPE_PMRS,
  DATA_TYPE_RESTAURANTS,
  DATA_TYPE_RESTAURANT_CATEGORIES,
  DATA_TYPE_SERVICES,
  DATA_TYPE_SERVICE_CATEGORIES,
  DATA_TYPE_SPEAKERS,
  DATA_TYPE_SPEAKER_CATEGORIES,
  DATA_TYPE_DOCUNITS,
  DATA_TYPE_DOCUNIT_CATEGORIES,
  DATA_TYPE_DOCUMENTS,
  DATA_TYPE_MONUMENTS,
  DATA_TYPE_PAPER_CATEGORIES,
  DATA_TYPE_PAPERS,
  DATA_TYPE_PARTICIPANTS,
  DATA_TYPE_CONTACTED_PARTICIPANTS,
  DATA_TYPE_CONTACTS,
  DATA_TYPE_FAVORITE_POSITIONS,
  DATA_TYPE_GOLDENSUMMIT,
  DATA_TYPE_GOLDENSUMMIT_CATEGORIES,
  DATA_TYPE_USERPROJECTS,
  DATA_TYPE_USERPROJECT_CATEGORIES,
  DATA_TYPE_JOBOFFERS,
  DATA_TYPE_JOBOFFER_CATEGORIES,
  CATEGORIES_DATA_TYPE,
  NON_CATEGORIES_DATA_TYPE,
  CATEGORIES_MAPPING,
  DATA_TYPE_MEETING_CONTACTS,
  VALID_DATA_TYPES,
  DATA_AUTO_UPDATE,
  AUTO_RELOAD_MAP_AT_UPDATE,
  DATA_TYPE_BUILDINGS,
  DATA_TYPE_FLOORS,
  DATA_TYPE_HAPPENINGS,
  DATA_TYPE_LIVESTREAM,
  DATA_TYPE_STORES,
  DATA_TYPE_STORE_CATEGORIES,
  FORCE_ONLINE_ASSETS,
  getSpeakerTitle,
  getSpeakerSubtitle,
  getExhibitorGaiaId,
  getParticipantExhibitorOriginalId,
  getParticipantExhibitorGaiaId
};