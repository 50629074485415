import { DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_SPEAKERS, DATA_TYPE_ANIMATIONS, DATA_TYPE_SERVICE_CATEGORIES } from './dataConfig';
const {
  DEFAULT_PROFILE,
  WEBAPP_EXH_PROFILE,
  WEBAPP_EVT_PROFILE
} = require('./profiles');
import { createItem, createItemLang, createItemRoute, createItemToggleLocation, createItemTogglePMR } from 'src/core/util/ConfigItemFactory';
import * as Db from 'src/core/data-and-assets/Db';
import { startUpdate } from 'src/core/data-and-assets/Updater';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import config from 'app-customs/config/config';
import { isIOS, isAndroid, isMobile } from 'src/core/util/browser';
import { getCurrent as getLang } from 'src/core/Lang';
import { FAVORITES_PAGE_KEY, HOME_PAGE_KEY, INBOX_PAGE_KEY, LIST_PAGE_KEY, MOBIGEO_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_PAGE_KEY, NOTES_PAGE_KEY, CHOOSE_PROFILE_PAGE_KEY } from 'src/pages/pagesKeys';
import { openUrl } from 'src/core/util/JsTools';
const LOG_PREF = '[menuConfig] ';

// @see https://onsen.io/v2/docs/react/SplitterSide.html
export const ONS_MENU_OPTIONS = {
  side: 'right',
  width: 210,
  swipeTargetWidth: '100%',
  collapse: true,
  swipeable: true
};

// documentation: https://material-ui.com/api/swipeable-drawer/#props
export const MENU_OPTIONS = {
  anchor: 'right',
  swipeAreaWidth: isMobile() ? 10 : 0 // default 20
};
const MEDIUM_SVG_SIZE = '1.7em';

// see https://github.com/markusenglund/react-switch#api
export const LOCATION_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
export const PMR_BUTTON_PROPS = {
  onColor: '#86d3ff',
  onHandleColor: '#2693e6',
  handleDiameter: 22,
  uncheckedIcon: false,
  checkedIcon: false,
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
  activeBoxShadow: '0px 0px 1px 10px rgba(0, 0, 0, 0.2)',
  height: 12,
  width: 36
};
const ICON_SIZE = '80%';

/**
 * Generate a button to toggle PMR status (used for mobigeo routing)
 */
export function getItemTogglePMR(labels) {
  return createItemTogglePMR(
  // style:
  {
    icon: {
      svgProps: {
        src: 'files/project/home/pmr.svg'
      },
      style: {
        // backgroundImage: `url(${getUrl('files/project/home/pmr.svg')})`,
        width: ICON_SIZE,
        height: ICON_SIZE
      }
    }
  },
  // label:
  labels.menu.pmr);
}
const getItemRouteHome = labels => createItemRoute(
// style:
{
  icon: {
    svgProps: {
      src: 'icons-svg/Home.svg'
    },
    style: {
      /* backgroundImage: `url("${homeIcon.replace(
        `fill='black'`,
        `fill='%23${MENU_ICON_COLOR}'`
      )}")`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
      width: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em',
      backgroundColor: '#17ccbe',
      borderRadius: '5px',
      padding: '3px',
      height: MEDIUM_SVG_SIZE
    }
  }
},
// label:
labels.home.title,
// page key:
HOME_PAGE_KEY);
const getItemRouteExhibitors = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/exposants.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.exhibitors,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
  }]
});
const getItemRouteNewProducts = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/nouveautés.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.newproducts,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
  }]
});
const getItemRouteConferences = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/programme.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.conferences,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_EVENT_CATEGORIES
  }]
});
const getItemRouteServices = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/services.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.services,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_SERVICE_CATEGORIES
  }]
});
const getItemRoutePracticalInfo = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/infos-pratiques.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.practicalInfo,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_SERVICES
  }]
});
const getItemRouteAnimations = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/animations.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.programme,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: true,
  inputs: [{
    dataType: DATA_TYPE_ANIMATIONS
  }]
});
const getItemRouteMicrons = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/concours.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.contests,
// page key:
LIST_PAGE_KEY,
// page props:
{
  locateAll: false,
  inputs: [{
    dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
  }]
});
const getItemRouteMap = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/plan.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.map,
// page key:
MOBIGEO_PAGE_KEY);
const getItemRouteSocialMedia = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/news.svg') + ')',
      backgroundSize: ICON_SIZE
    }
  }
},
// label:
labels.menu.medias,
// page key:
MEDIAS_PAGE_KEY);

/**
 * Generate a menu entry to route to Notes page
 */
function getItemRouteNotes(labels) {
  return createItemRoute(
  // style:
  {
    icon: {
      //className: 'sticky-note-icon',
      svgProps: {
        src: 'icons-svg/homeToolBar/sticky-note-solid.svg'
      },
      style: {
        /*  backgroundImage: `url(${getUrl('icons-svg/homeToolBar/sticky-note-solid.svg')})`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
        width: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em',
        backgroundColor: '#17ccbe',
        borderRadius: '5px',
        padding: '3px',
        height: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.notes.pageLinkTitle,
  // page key:
  NOTES_PAGE_KEY);
}
const getItemRouteSearch = labels => createItemRoute(
// style:
{
  icon: {
    svgProps: {
      src: 'icons-svg/Search.svg'
    },
    style: {
      /* backgroundImage: `url("${searchIcon.replace(
        `fill='black'`,
        `fill='%23${MENU_ICON_COLOR}'`
      )}")`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
      width: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em',
      backgroundColor: '#17ccbe',
      borderRadius: '5px',
      padding: '3px',
      height: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.search.title,
// page key:
SEARCH_PAGE_KEY);
const getItemRouteFavorites = labels => createItemRoute(
// style:
{
  icon: {
    svgProps: {
      src: 'icons-svg/Fav.svg'
    },
    style: {
      /*  backgroundImage: `url("${favIcon.replace(
        `fill='black'`,
        `fill='%23${MENU_ICON_COLOR}'`
      )}")`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
      width: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em',
      backgroundColor: '#17ccbe',
      borderRadius: '5px',
      padding: '3px',
      height: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.favorites.title,
// page key:
FAVORITES_PAGE_KEY);
const getItemRouteInbox = labels => createItemRoute(
// style:
{
  icon: {
    //className: 'envelope-icon',
    svgProps: {
      src: 'icons-svg/homeToolBar/envelope-solid.svg'
    },
    style: {
      /* backgroundImage: `url(${getUrl('icons-svg/homeToolBar/envelope-solid.svg')})`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
      width: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em',
      backgroundColor: '#17ccbe',
      borderRadius: '5px',
      padding: '3px',
      height: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.menu.inbox,
// page key:
INBOX_PAGE_KEY);
const getItemRouteSpeakers = labels => createItemRoute(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/intervenants.svg') + ')',
      backgroundSize: '60%'
    }
  }
},
// label:
labels.menu.speakers,
// page key:
LIST_PAGE_KEY,
// page props:
{
  contextualTitle: '',
  inputs: [{
    dataType: DATA_TYPE_SPEAKERS
  }]
});

/**
 * Generate item to open twitter page (inapp-browser)
 */
const getItemTwitter = (labels, actions) => createItem(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/twitter.svg') + ')',
      backgroundSize: '65%'
    }
  }
},
// label:
labels.menu.twitter,
// action:
() => {
  let url = 'https://twitter.com/UnionHLM';
  if (openUrl(url)) {
    actions.linkClicked(url);
  }
});

/**
 * Generate item to open LiveWall webapp (inapp-browser)
 */
const getItemLiveWall = (labels, actions) => createItem(
// style:
{
  icon: {
    style: {
      backgroundImage: 'url(' + getUrl('files/project/home/livewall.svg') + ')',
      backgroundSize: '58%'
    }
  }
},
// label:
labels.menu.livewall,
// action:
() => {
  let url = 'http://www.livewall.fr/mobile/mobile-message.php?id_event=550';
  if (openUrl(url)) {
    actions.linkClicked(url);
  }
});
function getItemLang(labels, actions) {
  return createItem(
  // style:
  {
    icon: {
      svgProps: {
        src: 'icons-svg/langue.svg'
      },
      style: {
        /* backgroundImage: `url(${getUrl('icons-svg/fiche/globe-solid.svg')})`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
        width: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em',
        backgroundColor: '#17ccbe',
        borderRadius: '5px',
        padding: '3px',
        height: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  labels.menu.language,
  // function to execute:
  actions.showChooseLangDialog);
}

/**
 * Generate a menu entry to open TOU link
 */
const getActionTOU = (labels, actions) => createItem(
// style:
{
  icon: {
    //className: 'file-icon',
    svgProps: {
      src: 'icons-svg/cgu.svg'
    },
    style: {
      /*  backgroundImage: `url(${getUrl('files/project/home/cgu.svg')})`,
      backgroundSize: MEDIUM_ICON_SIZE,
      backgroundPosition: 'center', */
      width: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em',
      backgroundColor: '#17ccbe',
      borderRadius: '5px',
      padding: '3px',
      height: MEDIUM_SVG_SIZE,
      lineHeight: '1.4em'
    }
  }
},
// label:
labels.menu.cgu,
// function to execute:
() => {
  if (openUrl(config.TOU_LINK[labels.id], null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
    actions.linkClicked(config.TOU_LINK[labels.id]);
  }
});

/**
 * Generate a menu entry to route to Choose profile page
 */
function getItemRouteChooseProfile(labels, profile) {
  return createItemRoute(
  // style:
  {
    icon: {
      //className: 'user-icon',
      svgProps: {
        src: 'icons-svg/fiche/groupe.svg'
      },
      style: {
        /*  backgroundImage: `url(${getUrl('icons-svg/Profil.svg')})`,
        backgroundSize: MEDIUM_ICON_SIZE,
        backgroundPosition: 'center', */
        width: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em',
        backgroundColor: '#17ccbe',
        borderRadius: '5px',
        padding: '3px',
        height: MEDIUM_SVG_SIZE,
        lineHeight: '1.4em'
      }
    }
  },
  // label:
  (profile ? "<div class=\"menu-text-current-profile\">".concat(profile, "</div>") : '') + labels.menu.toggleProfile,
  // page key:
  CHOOSE_PROFILE_PAGE_KEY,
  // Page props:
  null);
}

/**
 * Generate a menu entry using configuration from a config.json menubuttons item
 */
function getAdMenuButton(conf, labels, actions) {
  if (!conf) {
    return null;
  }
  return createItem(
  // style:
  {
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(conf["img_".concat(labels.id)]), ")"),
        backgroundSize: ICON_SIZE
      }
    }
  },
  // label:
  conf["label_".concat(labels.id)],
  // function to execute:
  () => {
    const url = conf["link_".concat(labels.id)];
    if (openUrl(url)) {
      actions.linkClicked(url);
    }
  });
}

/**
 * Menu configuration
 */
const getDefaultConfig = (actions, labels, adConfig, profile) => {
  let conf = {
    default: [getItemRouteHome(labels), getItemRouteExhibitors(labels), getItemRouteMap(labels), getItemRouteAnimations(labels), getItemRouteConferences(labels), getItemRouteMicrons(labels), getItemRoutePracticalInfo(labels),
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Lang
    getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels)]
  };

  /* if (adConfig && adConfig.menubuttons) {
  Object.keys(adConfig.menubuttons).forEach((menuBtnKey) => {
    conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
  });
  } */

  conf.default.push(getActionTOU(labels, actions));
  return conf;
};

/**
 * Menu configuration for  exh webapp profile
 */
const getWebAppExhConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [
    // exposants
    getItemRouteExhibitors(labels), getItemRouteMap(labels),
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Lang
    getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};
/**
 * Menu configuration for evt webapp profile
 */
const getWebAppEvtConfig = (actions, labels, adConfig, profile) => {
  const conf = {
    default: [getItemRouteConferences(labels),
    // getItemRouteSpeakers(labels),
    getItemRouteMap(labels),
    // Favorites
    getItemRouteFavorites(labels),
    // Notes
    config.NOTES && config.NOTES.FEATURE_ENABLED ? getItemRouteNotes(labels) : null,
    // Search
    getItemRouteSearch(labels),
    // Lang
    getItemLang(labels, actions),
    // Notifications
    getItemRouteInbox(labels)]
  };
  if (adConfig && adConfig.menubuttons) {
    Object.keys(adConfig.menubuttons).forEach(menuBtnKey => {
      conf.default.push(getAdMenuButton(adConfig.menubuttons[menuBtnKey], labels, actions));
    });
  }
  return conf;
};

/**
 * Return menu configuration
 * @param  {string} profile
 * @return {object}
 */
export const get = function () {
  let profile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_PROFILE;
  let page = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  let actions = arguments.length > 2 ? arguments[2] : undefined;
  let labels = arguments.length > 3 ? arguments[3] : undefined;
  let adConfig = arguments.length > 4 ? arguments[4] : undefined;
  let twoColumns = arguments.length > 5 ? arguments[5] : undefined;
  let conf;
  // Get menu config depending on user profile
  switch (profile) {
    case DEFAULT_PROFILE:
      conf = getDefaultConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    case WEBAPP_EXH_PROFILE:
      conf = getWebAppExhConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    case WEBAPP_EVT_PROFILE:
      conf = getWebAppEvtConfig(actions, labels, adConfig, labels.profile[profile]);
      break;
    // else undefined is returned
    default:
      console.error("".concat(LOG_PREF, "Unsupported profile"), profile);
  }

  // Return config depending on page
  if (conf) {
    // Filter any empty entry
    Object.keys(conf).forEach(page => {
      conf[page] = conf[page].filter(menuItemConfig => menuItemConfig);
    });
    return conf[page] || conf.default;
  }
  // else undefined is returned
};