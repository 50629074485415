module.exports = {
  AutoHideSplashScreen: {
    ios: 'false',
    android: 'true'
  },
  FadeSplashScreen: {
    ios: 'false',
    android: 'false'
  },
  FadeSplashScreenDuration: {
    ios: 0,
    android: 750
  },
  ShowSplashScreenSpinner: 'false',
  SplashScreenDelay: '1000',
  AndroidPostSplashScreenTheme: '@style/Theme.AppCompat.NoActionBar',
  AndroidWindowSplashScreenAnimatedIcon: 'res/drawable-port-xxxhdpi/splash.png',
  AndroidWindowSplashScreenAnimationDuration: '1000',
  AndroidWindowSplashScreenBackground: '#1d1d1b',
  AndroidWindowSplashScreenBrandingImage: undefined,
  // issue tracker https://issuetracker.google.com/issues/194301890
  AndroidWindowSplashScreenIconBackgroundColor: '#1d1d1b',
  SplashShowOnlyFirstTime: 'false',
  SplashScreenBackgroundColor: '0x9B5C9',
  BackgroundColor: '0x9B5C9',
  SplashScreenSpinnerColor: '#1d1d1b',
  RemoveSplashScreenOnPause: 'false',
  SplashMaintainAspectRatio: 'true',
  ShowSplashScreen: 'false'
};