/**
 *
 * Configuration describing how 2 pages are displayed simultaneously, screen being vertically split
 *
 */

import config from 'app-customs/config/config';
import {
// AIRCRAFT_PAGE_KEY,
EVENT_PAGE_KEY, EXHIBITOR_PAGE_KEY, EXPERIENCE_ITEM_PAGE_KEY, LIST_PAGE_KEY, NEWPRODUCT_PAGE_KEY,
// RESTAURANT_PAGE_KEY,
SERVICE_PAGE_KEY } from 'src/pages/pagesKeys.js';
import { DATA_TYPE_ANIMATION_CATEGORIES, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_SERVICE_CATEGORIES } from 'app-customs/config/dataConfig';
export const FEATURE_ACTIVATED = true;

/**
 * Required min width to auto-enable this mode
 * @type {Number}
 */
export const MIN_WIDTH = 800;
export const AREA = {
  right: 'right',
  left: 'left'
};

/**
 * Default area for all pages
 */
export const TWO_COLUMNS_DEFAULT = AREA.left;

/**
 * Every page NOT declared here will rely on `TWO_COLUMNS_DEFAULT`
 */
export const PAGES_SETUP = {
  // [AIRCRAFT_PAGE_KEY]  : AREA.right,
  [EVENT_PAGE_KEY]: AREA.right,
  [EXHIBITOR_PAGE_KEY]: AREA.right,
  [EXPERIENCE_ITEM_PAGE_KEY]: AREA.right,
  [config.MAP.DEFAULT_MAP_PAGE_KEY]: AREA.right,
  [NEWPRODUCT_PAGE_KEY]: AREA.right,
  //    [RESTAURANT_PAGE_KEY]   : AREA.right,
  [SERVICE_PAGE_KEY]: AREA.right
};

/**
 * Indicate in which columns a page should be displayed
 * @param  {string} pageKey
 * @return {string}
 * @see    pagesTwoColumnsConfig.AREA
 */
export const getArea = pageKey => PAGES_SETUP[pageKey] || TWO_COLUMNS_DEFAULT;

/**
 * Toolbar is displayed on a single area. Define here on which one
 */
export const TOOLBAR_AREA = AREA.left;

/**
 * CONFIGURE THIS FOR EVERY PROJECT
 */
export function getSecondPage(displayedPageKey, displayedPageProps, profile) {
  let displayedPageArea = getArea(displayedPageKey);

  // If displayed page is on left side, then display Map on the right side
  if (displayedPageArea === AREA.left) {
    return {
      pageKey: config.MAP.DEFAULT_MAP_PAGE_KEY,
      props: null
    };
  } else {
    switch (displayedPageKey) {
      case EVENT_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_EVENT_CATEGORIES
            }]
          }
        };
      case NEWPRODUCT_PAGE_KEY:
      case EXHIBITOR_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
            }]
          }
        };
      case SERVICE_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_SERVICE_CATEGORIES
            }]
          }
        };
      case EXPERIENCE_ITEM_PAGE_KEY:
        return {
          pageKey: LIST_PAGE_KEY,
          props: {
            locateAll: false,
            inputs: [{
              dataType: DATA_TYPE_ANIMATION_CATEGORIES
            }]
          }
        };
      case config.MAP.DEFAULT_MAP_PAGE_KEY:
        return config.getHomePage(profile);
    }
  }
}

/**
 * Declare fiche page keys for which POI should be automatically displayed on map
 * @type {Array}
 */
export const AUTOSHOW_POI_ON_MAP = {
  // [AIRCRAFT_PAGE_KEY]  : true,
  // [EVENT_PAGE_KEY]     : true,
  // [EXHIBITOR_PAGE_KEY] : true,
  // [RESTAURANT_PAGE_KEY]: true,
  // [SERVICE_PAGE_KEY]   : true,
};