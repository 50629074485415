module.exports = {
  "name": "micronora",
  "client": "Micronora",
  "description": "App for MICRONORA  event",
  "validLangs": ["fr", "en"],
  "version": {
    "mainVersion": "4.0.0",
    "buildVersion": {
      "iOS": "63",
      "android": "64",
      "web": "75"
    }
  },
  "native": {
    "androidSigning": {
      "keyAlias": "mobile-spot",
      "keyStorePassword": "jee1Uu0Hieloh7bi"
    },
    "app": "app-react",
    "name": {
      "default": "Micronora_D"
    },
    "id": "com.mobilespot.micronora.dev",
    "appleTeamId": "2BAP3P29V2",
    "playStoreUrl": ""
  },
  "undeliveredFolders": ["source", "exports"],
  "cordova": true,
  "crypto": false,
  "geoGroup": {},
  "web": {
    "url": "micronora2024.ms-dev.mobile-spot.com"
  },
  "relatedRepos": [{
    "src": "git@bitbucket.org:mobilespotdev/cordova.git",
    "branch": "inte/micronora/main",
    "postCommands": ["cd cordova && npm install"]
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo.git",
    "branch": "inte/micronora/main",
    "postCommands": ["cd mobigeo && npm install"]
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo-customs-master.git",
    "branch": "inte/micronora/main",
    "dest": "mobigeo/mobigeo-customs",
    "postCommands": ["cd mobigeo/mobigeo-customs && npm install"]
  }, {
    "src": "git@bitbucket.org:mobilespotdev/app-react.git",
    "branch": "inte/micronora/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/data-master.git",
    "branch": "inte/micronora/main",
    "dest": "app-react/app-customs",
    "postCommands": ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }]
};